import React from "react"
import PropTypes from "prop-types"
import DollarsInput from './DollarsInput'
import PercentageInput from './PercentageInput'
import NoteInput from './NoteInput'
import ReactTooltip from 'react-tooltip'

import * as moment from 'moment';
import MomentLocaleUtils, {
  formatDate,
  parseDate,
} from 'react-day-picker/moment';
import DayPickerInput from 'react-day-picker/DayPickerInput';

import { Container, Row, Col, Button, Alert, Table, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import Toggle from 'react-toggle'

import { create, all } from 'mathjs'
import { Pie } from 'react-chartjs-2';
import 'chartjs-plugin-labels';

const math = create(all, { precision: 128 });

const colors = [
      '#C9202C',
      '#086788',
      '#07A0C3',
      '#F0C808',
      '#FFF1D0',
      '#E89005',
      '#FE4E00',
      '#FCD581',
      '#D6A99A',
      '#D6CBC1',
      '#FF0F80',
      '#C9DAEA',
      '#03F7EB',
      '#00B295',
      '#A9E5BB',
      '#89BD9E',
      '#CDD6D0',
      '#B9CFD4',
      '#AFAAB9',
      '#B49291',
      '#A23B72',
      '#8B1E3F',
      '#3C153B',
      '#2D1E2F'
    ];

export default class NoteCalculator extends React.Component {
  constructor(props) {
    super(props);

    console.log("Hey there! 👋 Feel free to take a look around. You can reach our Director of Engineering at afanelli at 645ventures.com if you have any questions.")

    const roundSize = 8000000
    const postMoney = 32000000
    const dilution = math.evaluate(roundSize / postMoney * 100)

    this.state = {
      fields: {
        roundSize: roundSize,
        postMoney: postMoney,
        optionPool: 10,
        impliedOptionPool: 10,
        dilutedOptionPool: 7.5,
        optionsAreOnPostMoneyCap: false
      },
      seriesADate: moment().add(12, 'months').toDate(),
      noteCounter: 1,
      dilution: dilution,
      seriesAOwnership: dilution,
      investorsDilution: dilution,
      rounds: [],
      rows: [],
      headers: []
    }

    this.updateNoteDetails = this.updateNoteDetails.bind(this)
    this.updateOptionPoolDilution = this.updateOptionPoolDilution.bind(this)
    this.updateSeriesAOwnership = this.updateSeriesAOwnership.bind(this)
    this.handleDayClick = this.handleDayClick.bind(this)
    this.createNewNote = this.createNewNote.bind(this)
    this.removeNote = this.removeNote.bind(this)
    this.foundersOwnership = this.foundersOwnership.bind(this)
    this.generateHeaders = this.generateHeaders.bind(this)
    this.updateOptionPool = this.updateOptionPool.bind(this)
  }

  componentDidMount() {
    this.updateSeriesAOwnership()
  }

  updateOptionPoolDilution() {
    let fields = this.state.fields;
    fields.optionsAreOnPostMoneyCap = !this.state.fields.optionsAreOnPostMoneyCap;

    let impliedOptionPool = fields.impliedOptionPool
    let dilutedOptionPool = fields.dilutedOptionPool

    if (fields.optionsAreOnPostMoneyCap) {
      impliedOptionPool = math.evaluate(fields.optionPool / (1 - (this.state.seriesAOwnership / 100)))
      dilutedOptionPool = fields.optionPool
    } else {
      impliedOptionPool = fields.optionPool
      dilutedOptionPool = math.evaluate(fields.optionPool * (1 - (this.state.seriesAOwnership / 100)))
    }

    fields.impliedOptionPool = impliedOptionPool
    fields.dilutedOptionPool = dilutedOptionPool

    this.setState({ fields }, this.updateSeriesAOwnership);
  }

  updateOptionPool(event) {
    const optionPool = event.target.value.replace(/[,M$%]/g, '');
    if (optionPool != 0 && !optionPool) return 0

    let fields = this.state.fields
    let impliedOptionPool, dilutedOptionPool

    if (fields.optionsAreOnPostMoneyCap) {
      impliedOptionPool = math.evaluate(optionPool / (1 - (this.state.seriesAOwnership / 100)))
      dilutedOptionPool = optionPool
    } else {
      impliedOptionPool = optionPool
      dilutedOptionPool = math.evaluate(optionPool * (1 - (this.state.seriesAOwnership / 100)))
    }

    fields.impliedOptionPool = parseFloat(impliedOptionPool || 0)
    fields.dilutedOptionPool = parseFloat(dilutedOptionPool || 0)
    fields.optionPool = parseFloat(optionPool || 0)
    console.log(fields)
    this.setState({ fields }, this.updateSeriesAOwnership)
  }

  updateField(fieldName, value) {
    if (value) {
      let formFields = this.state.fields;
      formFields[fieldName] = parseFloat(value);
      this.setState({ fields: formFields }, this.updateSeriesAOwnership);
    }
  }

  updateMaskedField(fieldName, event) {
    const amount = event.target.value.replace(/[,M$%]/g, '');
    this.updateField(fieldName, amount);
  }

  roundsWithProRata() {
    return this.state.rounds.filter((round) => {
      return this.state[`${round.name}ProRata`]
    })
  }

  updateSeriesAOwnership() {
    this.setState({
      seriesAOwnership: math.evaluate(this.state.fields.roundSize / this.state.fields.postMoney * 100)
    }, this.generateGrid)
  }

  generateGrid() {
    this.setState({roundSizes: [], headers: [], rows: []}, this.generateHeaders)
  }

  generateHeaders() {
    const roundSizes = []
    const thObjects = [0,1,2,3,4].map((millionsToAdd) => {
      const newValuation = math.evaluate(this.state.fields.roundSize + (millionsToAdd * 2 * 1000000))
      roundSizes.unshift(newValuation)
      return <th key={newValuation}>${newValuation / 1000000}M</th>
    })

    this.setState({roundSizes: roundSizes, headers: thObjects}, this.generateRows)
  }

  formatMoney(value) {
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0
    })

    return formatter.format(value)
  };

  generateRows() {
    const rows = [0,1,2,3,4].map((millionsToAdd, rowsIndex) => {
      const newValuation = this.state.fields.postMoney + (millionsToAdd * 2 * 1000000)

      const values = [0,1,2,3,4].map((index) => {
        return this.foundersOwnership(newValuation, index)
      })

      return (
        <tr key={`${millionsToAdd}-${rowsIndex}`}>
          <th scope="row">${newValuation / 1000000}M</th>
          {values.reverse().map((value) => {
            if (value != undefined) {
              let ownershipType = null
              if (value >= 50) ownershipType = 'good'
              if (value < 50) ownershipType = 'medium'
              if (value <= 25) ownershipType = 'bad'
              return <td className={`${ownershipType}-ownership`} key={value}>{value.toFixed(2)}%</td>
            }
          })}
        </tr>
      )
    })

    this.setState({ rows: rows })
  }

  sumArray(array) {
    return array.reduce((a,b) => a + b, 0)
  }

  foundersOwnership(seriesAValuation, index) {
    if (!this.state.roundSizes[index]) return

    const seriesADilution = math.evaluate(this.state.roundSizes[index] / seriesAValuation)

    const rounds = this.state.rounds.sort(function(a,b){
      return new Date(b.roundDate) - new Date(a.roundDate);
    })

    let foundersOwnership = this.fullyDilutedRoundsOwnerships(rounds).foundersOwnership

    const projectedOwnership = math.evaluate(foundersOwnership * (1 - seriesADilution) * (1 - (this.state.fields.impliedOptionPool / 100)))

    return projectedOwnership < 0 ? 0 : projectedOwnership
  }

  updateNoteDetails(id, fields) {
    let rounds = this.state.rounds;

    const index = rounds.findIndex((round) => { return round.id == id })

    rounds[index] = fields

    this.setState({rounds: rounds}, this.generateGrid);
  }

  createNewNote() {
    const noteCount = this.state.noteCounter

    const newRound = {
      name: `Round #${noteCount}`,
      impliedOwnership: null,
      investorsOwnership: null,
      roundType: 'safe',
      collapsed: false,
      roundTypeOption: { label: 'SAFE', value: 'safe' },
      usePreMoneyCap: true,
      selectedCapOption: { label: 'Pre Money Cap', value: 'pre' },
      accruedInterest: 0,
      impliedOwnership: 0,
      monthsToMaturity: 18,
      capConversion: false,
      uncapped: false,
      optionPool: 0,
      impliedOptionPool: 0,
      optionsAreOnPostMoneyCap: false,
      preMoney: 6000000,
      postMoney: 7000000,
      roundSize: 1000000,
      proRataRightsCapital: 1000000,
      maturityCap: 5000000,
      discount: 20,
      interestRate: 5,
      impliedDilutedOwnership: null,
      id: `note-${noteCount}`,
      roundDate: moment().add(this.state.noteCounter, 'months').toDate()
    }

    let rounds = this.state.rounds;
    rounds.push(newRound)
    this.setState({rounds: rounds, noteCounter: noteCount + 1})
  }

  removeNote(id) {
    let activeNotes = this.state.rounds.filter((round) => { return round.id != id })
    this.setState({rounds: activeNotes}, this.updateSeriesAOwnership)
  }

  handleDayClick(day, { selected }) {
    this.setState({
      seriesADate: selected ? undefined : day,
    });
  }

  fullyDilutedRoundsOwnerships(rounds) {
    let foundersOwnership = 100

    let fullyDilutedInvestorsOwnership = []
    let fullyDilutedOptionPools = []
    let impliedOptionPools = []

    const sortedRounds = rounds.sort(function(a,b){
      return new Date(b.roundDate) - new Date(a.roundDate);
    })

    sortedRounds.map(round => {
      let roundInvestorOwnership = round.impliedOwnership
      // Implied option pool might differ from the actual if on a pre-money basis.
      // This means we have to dilute previous rounds by the implied, but then dilute the
      // actual one by the following rounds %
      let impliedOptionPool = round.impliedOptionPool
      let optionPool = round.dilutedOptionPool
      const isPreMoneyOptionPool = round.optionsAreOnPostMoneyCap

      if (fullyDilutedInvestorsOwnership.length > 0) {
        fullyDilutedInvestorsOwnership.map((roundInvestorsDilution, index) => {
          const dilutionAmount = math.evaluate((1 - (roundInvestorsDilution / 100.0)) * (1 - (impliedOptionPools[index] / 100)))
          roundInvestorOwnership = math.evaluate(roundInvestorOwnership * dilutionAmount)
          optionPool = math.evaluate(optionPool * (1 - (roundInvestorsDilution / 100.0)))
        })
      }

      foundersOwnership = math.evaluate(foundersOwnership * (1 - (impliedOptionPool / 100.0)) * (1 - (roundInvestorOwnership / 100.0)))

      impliedOptionPools.push(impliedOptionPool)
      fullyDilutedInvestorsOwnership.push(roundInvestorOwnership)
      fullyDilutedOptionPools.push(optionPool)
    })

    const seriesADilution = math.evaluate((1 - (this.state.fields.impliedOptionPool / 100.0)) *
                                          (1 - (this.state.seriesAOwnership / 100.0)))

    const seriesAOwnerships = fullyDilutedInvestorsOwnership.map((roundOwnership) => {
      return math.evaluate(roundOwnership * seriesADilution)
    })

    const seriesAOptionPools = fullyDilutedOptionPools.map((roundOptionPool) => {
      return math.evaluate(roundOptionPool * seriesADilution)
    })

    return {
      fullyDilutedInvestorsOwnership: fullyDilutedInvestorsOwnership,
      fullyDilutedOptionPools: fullyDilutedOptionPools,
      foundersOwnership: foundersOwnership,
      seriesAOwnerships: seriesAOwnerships,
      seriesAOptionPools: seriesAOptionPools
    }
  }

  ownershipPieChart() {
    const rounds = this.state.rounds.sort(function(a,b){
      return new Date(b.roundDate) - new Date(a.roundDate);
    })

    const fullyDilutedRoundsData = this.fullyDilutedRoundsOwnerships(rounds)

    let fullyDilutedRounds = fullyDilutedRoundsData.seriesAOwnerships

    const ownershipLabels = ['Founders', 'Option Pool', 'Series A'].concat(rounds.map((round) => { return round.name }))

    const totalOptionPools = this.sumArray(fullyDilutedRoundsData.seriesAOptionPools) + this.state.fields.dilutedOptionPool

    let founderOwnership = null

    founderOwnership = math.evaluate(fullyDilutedRoundsData.foundersOwnership *
                                     (1 - (this.state.fields.impliedOptionPool / 100.0)) *
                                     (1 - (this.state.seriesAOwnership / 100.0)))

    return (
      <Pie
        height={200}
        data={{
            labels: ownershipLabels,
            datasets: [
              {
                label: 'Ownership after Series A',
                backgroundColor: colors,
                data: [founderOwnership, totalOptionPools, this.state.seriesAOwnership].concat(fullyDilutedRounds),
                borderWidth: 0,
              },
            ]
          }}
          options={{
            legend: {
              display: true,
              position: 'top',
              labels: {
                boxWidth: 15
              }
            },
            plugins: {
              labels: []
            },
            tooltips: {
              titleFontSize: 16,
              bodyFontSize: 14,
              displayColors: false,
              callbacks: {
                label: (tooltipItem, data) => {
                  const dataset = data.datasets[tooltipItem.datasetIndex];
                  const meta = dataset._meta[Object.keys(dataset._meta)[0]];
                  const total = meta.total;
                  const currentValue = dataset.data[tooltipItem.index];
                  return `${parseFloat(currentValue).toFixed(2)}%`;
                },
                title: (tooltipItem, data) => {
                  return data.labels[tooltipItem[0].index];
                },
              },
            }
          }}
     />
    )
  }

  dollarsPieChart() {
    const proRataRoundsLabels = []
    const proRataRoundAmounts = []

    const proRataInvestments = this.roundsWithProRata().map((round) => {
      proRataRoundsLabels.unshift(`${round.name} Investors Pro Rata $ Amount`)
      const proRataOwnership = math.evaluate(round.proRataRightsCapital / round.postMoney * 100)
      const dilutedProRataOwnership = math.evaluate(proRataOwnership * (1 - (this.state.dilution / 100)))
      const ownershipToBuy = proRataOwnership - dilutedProRataOwnership

      proRataRoundAmounts.unshift(math.evaluate(parseFloat(this.state.fields.postMoney) * ownershipToBuy / 100))
    })

    proRataRoundsLabels.unshift('Series A Investors $ Amount')
    proRataRoundAmounts.unshift(this.state.fields.roundSize - this.sumArray(proRataRoundAmounts))

    return (
      <Pie
        data={{
            labels: proRataRoundsLabels,
            datasets: [
              {
                label: 'Amount invested at Series A',
                backgroundColor: colors,
                data: proRataRoundAmounts,
                borderWidth: 0,
              },
            ]
          }}
          options={{
            legend: {
              display: true,
              position: 'bottom'
            },
            plugins: {
              labels: []
            },
            tooltips: {
              titleFontSize: 16,
              bodyFontSize: 14,
              displayColors: false,
              callbacks: {
                label: (tooltipItem, data) => {
                  const dataset = data.datasets[tooltipItem.datasetIndex];
                  const meta = dataset._meta[Object.keys(dataset._meta)[0]];
                  const total = meta.total;
                  const currentValue = dataset.data[tooltipItem.index];
                  return `${this.formatMoney(currentValue)}`;
                },
                title: (tooltipItem, data) => {
                  return data.labels[tooltipItem[0].index];
                },
              },
            }
          }}
     />
    )
  }

  handleProRataToggle(key) {
    let state = this.state;
    state[key] = !state[key]
    this.setState(state, this.updateSeriesAOwnership)
  }

  infoModal() {
    const toggle = () => { this.setState({showModal: !this.state.showModal})}

    return (
      <Modal isOpen={this.state.showModal}
             toggle={toggle}
             className='info-modal'>
        <ModalHeader toggle={toggle}>Cap Table Simulator Glossary</ModalHeader>
        <ModalBody>
          <b>Amount Raised:</b> The total amount of money raised in the current round<br /><br />
          <b>Pre-money:</b> The valuation of the company before new money (round size) is added to the valuation<br /><br />
          <b>Post-money:</b> The valuation of the company after adding new money (round size) to the valuation<br /><br />
          <b>Pro-rata:</b> Gives the investor a right (but not the obligation) to participate in  future financing rounds to maintain their percentage ownership stake<br /><br />
          <b>Implied ownership:</b> The hypothetical valuation of a company when shares aren't actually issued for a specified price<br /><br />
          <b>Discount:</b> Permits an investor to convert the principal amount of their note or SAFE (plus any accrued interest) into shares of stock at a discount to the purchase price paid by investors in that round.<br /><br />
          <b>Maturity Date:</b> The date when the note is repaid to the investor, or automatically converts to equity, if a priced round (i.e. qualified financing) doesn't happen. This is typically at the choice of the investor.<br /><br />
          <b>Conversion @ maturity:</b> The valuation of the company at the maturity date if a priced round doesn't happen<br /><br />
          <b>Interest rate:</b> The amount of interest accrued to the investors prior to the note's conversion to equity <br /><br />
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggle}>Close</Button>
        </ModalFooter>
      </Modal>
    )
  }

  render() {
    return (
      <Container fluid={true} className='notes-container'>
        <Row className='header'>
          <Col>
            <h3>
              <a href='https://645ventures.com' target='_blank'>
                <img src='/logo.png' />
              </a>

              Cap Table Simulator

              <Button color="primary" className='add-new-button' onClick={this.createNewNote}>
                Add New Round
              </Button>

              <Button onClick={() => { this.setState({showModal: true})}}
                      className='what-is-this' color='primary'>
                Glossary
              </Button>
            </h3>
          </Col>
        </Row>

        <Row>
          {this.state.rounds.length > 0 ?
            this.state.rounds.sort(function(a,b){
              return new Date(a.roundDate) - new Date(b.roundDate);
            }).map((round) => {
              return <Col sm='12' md='6' lg='4' key={`${round.id}-${this.state.fields}`}>
                <NoteInput round={round}
                           removeNote={this.removeNote}
                           updateNoteDetails={this.updateNoteDetails}
                           seriesAPostMoney={this.state.fields.postMoney}
                           seriesASize={this.state.fields.roundSize}
                           seriesADilution={math.evaluate((1 - (this.state.fields.impliedOptionPool / 100.0)) *
                                                          (1 - (this.state.seriesAOwnership / 100.0)))}
                           seriesADate={this.state.seriesADate} />
              </Col>
            })
          : <Col><h4 className='no-notes-message'>No rounds have been added. Use the button above to add one.</h4></Col>}
        </Row>

        <Row>
          <Col md='4' sm='4' xs='12'>
            <div className='note-input'>
              <h6 style={{fontWeight: '700'}}>Series A <span className='qualified-financing'>(Qualified Financing)</span></h6>

              <div className='series-a-ownership'>{this.state.seriesAOwnership.toFixed(2)}% dilution</div>

              <Row>
               <Col>
                 <div className='note-calculator-label'
                      data-tip="The total amount of money raised in the current round">
                   Round Size
                 </div>

                 <DollarsInput
                   className='form-control'
                   onChange={(value) => this.updateMaskedField('roundSize', value)}
                   value={this.state.fields.roundSize}
                 />
                </Col>
              </Row>
              <Row>
                <Col>
                 <div className='note-calculator-label'
                      data-tip="The valuation of the company after adding new money (round size) to the valuation">
                   Post Money Valuation
                 </div>

                 <DollarsInput
                   className='form-control'
                   onChange={(value) => this.updateMaskedField('postMoney', value)}
                   value={this.state.fields.postMoney}
                 />
                </Col>
              </Row>

              <Row>
                <Col>
                  <div className='note-calculator-label'
                       data-tip="The day in which the round is closed. If it's a convertible note, it will start accruing interest after this date.">
                    Round Date
                  </div>

                  <DayPickerInput
                    formatDate={formatDate}
                    parseDate={parseDate}
                    onDayChange={this.handleDayClick}
                    value={this.state.seriesADate}
                    selectedDays={this.state.seriesADate}
                    inputProps={{className: 'form-control'}}
                    dayPickerProps={{
                      month: this.state.seriesADate,
                    }}
                   />
                 </Col>
               </Row>

               <Row>
                 <Col>
                   <div className='note-calculator-label'
                        data-tip="Amount of equity reserved for employees stock options.">
                     Employees Option Pool
                   </div>
                   <PercentageInput
                     className='form-control'
                     onChange={this.updateOptionPool}
                     value={this.state.fields.optionPool}
                   />
                 </Col>
               </Row>
               <Row style={{alignItems: 'center'}}>
                 <Col sm='3' md='3' lg='3'>
                   <Toggle
                     icons={{
                       checked: null,
                       unchecked: null,
                     }}
                     defaultChecked={this.state.fields.optionsAreOnPostMoneyCap}
                     onChange={this.updateOptionPoolDilution} />
                 </Col>
                 <Col sm='9' md='9' lg='9'>
                  <label>Option Pool is on a post-money basis?</label>
                 </Col>
               </Row>

               <Alert color='warning' isOpen={true} style={{marginTop: '18px'}}>
                 {this.state.fields.optionsAreOnPostMoneyCap ?
                   <span>Since the option pool is on a post-money basis, it will be diluted by {this.state.investorsDilution.toFixed(2)}% by investors in this round.
                   The pre-money option pool size will be grossed up to {this.state.fields.impliedOptionPool.toFixed(2)}% to account for that dilution.
                   This means previous investors and founders will be diluted an extra {(this.state.fields.impliedOptionPool - this.state.fields.optionPool).toFixed(2)}%</span> :
                   <span>Since the option pool is on a pre-money basis, it will be diluted by investors in this round by {this.state.seriesAOwnership.toFixed(2)}%.
                   This will dilute it from {this.state.fields.optionPool.toFixed(2)}% to {this.state.fields.dilutedOptionPool.toFixed(2)}%.</span>
                 }
               </Alert>

              <Alert color='danger' isOpen={this.state.fields.roundSize > this.state.fields.postMoney}>
                Your {this.props.name} round size is larger than the valuation of the round. You can't sell more than 100% of your equity!
              </Alert>
            </div>
          </Col>

          <Col md='8' sm='8' xs='12'>
            <Row>
              <Col md='7' sm='7' xs='12'>
                <h5 className='post-series-a-title'>Founders Ownership Post-Series A</h5>

                <div style={{marginLeft: '120px', fontWeight: '700'}}>Series A Round Size</div>
                <Table size='sm' hover responsive style={{overflow: 'hidden'}}>
                  <thead>
                    <tr>
                      <th />
                      <th />
                      {this.state.headers}
                    </tr>
                  </thead>
                  <tbody>
                    <tr><td className='rotate' rowSpan="6"><div>Series A Post-Money</div></td></tr>
                    {this.state.rows}
                  </tbody>
                </Table>

                <p className='upside-down-description'>
                  Avoid an <a href='https://medium.com/@645ventures/17f668520f8b' target='_blank'>upside down cap table</a>, which occurs when investors (in aggregate) own more of a startup than the founders and its employees.
                </p>
              </Col>

              <Col md='5' sm='5' xs='12'>
                <div style={{height: '250px'}}>
                  {this.ownershipPieChart()}
                </div>

                <Button className='toggle-amounts'
                        size='sm'
                        color='primary'
                        onClick={() => { this.setState({ showRoundComposition: !this.state.showRoundComposition}) }}>
                  {this.state.showRoundComposition ? 'Hide' : 'Show'} Series A Investment Amount Breakdown
                </Button>
              </Col>
            </Row>

            {this.state.showRoundComposition &&
              <React.Fragment>
                <Row>
                  <Col md='6' sm='6' xs='12'>
                    <p style={{marginTop: '16px'}}>
                      Understanding how your pro rata rights affects your Series A fundraise is crucial, as Series A funds have pretty set targets (~10-15% ownership).
                      If you have too much capital with pro rata filling out the syndicate, it might be hard to attract new investors given that they can't put much money to work. <br />
                    </p>
                  </Col>

                  <Col md='6' sm='6' xs='12'>
                    <div style={{height: '250px'}}>
                      {this.dollarsPieChart()}
                    </div>

                    <p className='note-input-round-name'>Pro Rata Rights</p>

                    {this.state.rounds.map((round) => {
                      const proRataKey = `${round.name}ProRata`;

                      return (
                        <Row className='toggle-row'>
                          <Toggle
                            icons={{
                              checked: null,
                              unchecked: null,
                            }}
                            defaultChecked={this.state[proRataKey]}
                            onChange={() => { this.handleProRataToggle(proRataKey) }} />
                          <label>Apply Pro-Rata Rights to {round.name}</label>
                        </Row>
                      )
                    })}
                  </Col>
                </Row>
              </React.Fragment>}
          </Col>
        </Row>

        <Row>
          <Col>
            <p className='disclaimer'>
              The Cap Table Simulator and all related data, information, and software has been prepared for informational purposes only, and is not intended to provide, and should not be relied on for investment advice. You should consult your own investment and legal advisors before engaging in any transaction.
            </p>
          </Col>
        </Row>



          {this.infoModal()}

          <ReactTooltip />
      </Container>
    );
  }
}
