import React from "react"
import PropTypes from "prop-types"
import DollarsInput from './DollarsInput'
import PercentageInput from './PercentageInput'
import ReactTooltip from 'react-tooltip'

import { Container, Button, Row, Col, Alert } from 'reactstrap';
import Select from 'react-select';

import { create, all } from 'mathjs'

import * as moment from 'moment';
import MomentLocaleUtils, {
  formatDate,
  parseDate,
} from 'react-day-picker/moment';
import DayPickerInput from 'react-day-picker/DayPickerInput';

import Toggle from 'react-toggle'

const math = create(all, {});

export default class NoteInput extends React.Component {
  constructor(props) {
    super(props);

    let roundFields = props.round;
    roundFields.maturityDate = moment(roundFields.roundDate).add(18, 'months').toDate()
    roundFields.collapsed = false

    this.state = roundFields;

    this.updatePreOrPostCap = this.updatePreOrPostCap.bind(this)
    this.updateRoundSize = this.updateRoundSize.bind(this)
    this.updatePreMoneyValuation = this.updatePreMoneyValuation.bind(this)
    this.updatePostMoneyValuation = this.updatePostMoneyValuation.bind(this)
    this.updateDiscount = this.updateDiscount.bind(this)
    this.updateRoundDate = this.updateRoundDate.bind(this);
    this.handleNameChange = this.handleNameChange.bind(this);
    this.updateRoundType = this.updateRoundType.bind(this);
    this.removeNote = this.removeNote.bind(this);
    this.updateOptionPool = this.updateOptionPool.bind(this);
    this.updateOptionPoolDilution = this.updateOptionPoolDilution.bind(this);
  }

  updateRoundDate(day, { selected }) {
    this.setState({
      roundDate: selected ? undefined : day,
    }, this.updateOwnership);
  }

  formatMoney(value) {
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0
    })

    return formatter.format(value)
  };

  componentDidMount() {
    this.updateOwnership()
  }

  sendDataUpstream() {
    this.props.updateNoteDetails(this.props.round.id, this.state)
  }

  updateOwnership() {
    let impliedOwnership, impliedDilutedOwnership = null
    let totalInvestmentAmount = parseFloat(this.state.roundSize)

    totalInvestmentAmount = math.evaluate(totalInvestmentAmount + this.accruedInterest())

    if (this.isPastMaturity()) {
      this.setState({capConversion: true})
      impliedOwnership = math.evaluate(totalInvestmentAmount / this.state.maturityCap * 100.00)
      impliedDilutedOwnership = math.evaluate(impliedOwnership * (1.0 - (this.props.seriesADilution / 100.0)))
    } else if (this.state.uncapped) {
      this.setState({capConversion: false})
      let discountedCap = math.evaluate(this.props.seriesAPostMoney * (1 - (this.state.discount / 100.0)))
      impliedOwnership = math.evaluate(totalInvestmentAmount / discountedCap * 100.00)
      impliedDilutedOwnership = impliedOwnership
    } else {
      // If the note cap is higher than the conversion cap, we use the discount
      if (this.state.postMoney >= this.props.seriesAPostMoney && this.state.roundType != 'priced') {
        this.setState({capConversion: false})
        let discountedCap = math.evaluate(this.props.seriesAPostMoney * (1 - (this.state.discount / 100.0)))
        impliedOwnership = math.evaluate(totalInvestmentAmount / discountedCap * 100.00)
        impliedDilutedOwnership = impliedOwnership
      } else {
        this.setState({capConversion: true})
        impliedOwnership = math.evaluate(totalInvestmentAmount / this.state.postMoney * 100.00)
        impliedDilutedOwnership = math.evaluate(impliedOwnership * (1.0 - (this.props.seriesADilution / 100.0)))
      }
    }

    const investorsOwnership = impliedOwnership

    let optionPool = this.state.optionPool
    let impliedOptionPool = optionPool
    let dilutedOptionPool = 0

    if (this.state.roundType == 'priced' && this.state.optionPool > 0) {
      // If the option pool is on a pre-money basis, it gets diluted by this round's dilution,
      // so it needs to be grossed up to be the actual amount post-round
      if (this.state.optionsAreOnPostMoneyCap) {
        impliedOptionPool = math.evaluate(optionPool / (1 - (impliedOwnership / 100)))
        dilutedOptionPool = optionPool
      } else {
        impliedOptionPool = optionPool
        dilutedOptionPool = math.evaluate(optionPool * (1 - (impliedOwnership / 100)))
      }
    }

    const seriesAOptionPool = math.evaluate(dilutedOptionPool * (1.0 - this.props.seriesADilution))

    this.setState({
      impliedOwnership: impliedOwnership,
      dilutedOptionPool: dilutedOptionPool,
      impliedOptionPool: impliedOptionPool,
      optionPool: optionPool,
      impliedDilutedOwnership: impliedDilutedOwnership,
      seriesADilutedOptionPool: seriesAOptionPool,
      investorsOwnership: investorsOwnership
    }, this.sendDataUpstream)
  }

  updatePreOrPostCap(value) {
    this.setState({
      uncapped: value.value == 'uncapped',
      usePreMoneyCap: value.value == 'pre',
      selectedCapOption: value
    }, this.updateOwnership)
  }

  updateRoundType(value) {
    let optionPool = this.state.optionPool
    let dilutedOptionPool = this.state.dilutedOptionPool
    let impliedOptionPool = this.state.impliedOptionPool

    if (value.value != 'priced') {
      optionPool = 0
      dilutedOptionPool = 0
      impliedOptionPool = 0
    }

    this.setState({
      roundType: value.value,
      optionPool: optionPool,
      dilutedOptionPool: dilutedOptionPool,
      impliedOptionPool: impliedOptionPool,
      roundTypeOption: value
    }, this.updateOwnership)
  }

  cleanInput(input) {
    if (input != 0 && !input) return
    return parseFloat(input.target.value.replace(/[,M$%]/g, ''))
  }

  handleNameChange(e) {
    this.setState({name: e.target.value}, this.sendDataUpstream)
  }

  accruedInterest() {
    if (this.state.roundType != 'note') {
      return 0
    }

    if (this.state.roundDate && this.props.seriesADate) {
      const selected = new Date(this.state.roundDate)
      const seriesA = new Date(this.props.seriesADate)
      const daysBetween = math.evaluate(((seriesA.getTime() - selected.getTime()) / (1000.0 * 3600 * 24)))
      const interest = this.state.roundSize * math.evaluate(this.state.interestRate / 100 / 365.0) * parseInt(daysBetween + 1)
      return interest >= 0 ? interest : 0
    } else {
      return 0
    }
  }

  updateRoundSize(value) {
    let roundSize = this.cleanInput(value)
    if (!roundSize) return

    let proRataRightsCapital = this.state.proRataRightsCapital

    if (roundSize < proRataRightsCapital) {
      proRataRightsCapital = roundSize
    }

    if (this.state.uncapped) {
      this.setState({
        roundSize: roundSize,
        proRataRightsCapital: proRataRightsCapital
       }, this.updateOwnership);
    } else if (this.state.usePreMoneyCap) {
      this.setState({
        roundSize: roundSize,
        postMoney: this.state.preMoney + roundSize,
        proRataRightsCapital: proRataRightsCapital
       }, this.updateOwnership);
    } else {
      this.setState({
        roundSize: roundSize,
        preMoney: this.state.postMoney - roundSize,
        proRataRightsCapital: proRataRightsCapital
       }, this.updateOwnership);
    }
  }

  updatePreMoneyValuation(value) {
    const preMoney = this.cleanInput(value);
    if (!preMoney) return

    this.setState({
      preMoney: preMoney,
      postMoney: preMoney + this.state.roundSize
    }, this.updateOwnership)
  }

  updatePostMoneyValuation(value) {
    const postMoney = this.cleanInput(value)
    if (!postMoney) return

    this.setState({
      preMoney: postMoney - this.state.roundSize,
      postMoney: postMoney
    }, this.updateOwnership)
  }

  updateDiscount(value) {
    let discount = this.cleanInput(value)
    if (discount != 0 && !discount) return

    discount = discount > 100 ? 100 : discount

    this.setState({ discount }, this.updateOwnership);
  }

  updateInterestRate(value) {
    const interestRate = this.cleanInput(value)
    if (interestRate != 0 && !interestRate) return

    this.setState({ interestRate }, this.updateOwnership);
  }

  updateMonthsToMaturity(event) {
    const monthsToMaturity = event.target.value;
    const maturityDate = moment(this.state.roundDate).add(monthsToMaturity, 'months').toDate()
    this.setState({ monthsToMaturity, maturityDate }, this.updateOwnership);
  }

  updateMaturityCap(value) {
    const maturityCap = this.cleanInput(value)
    if (!maturityCap) return

    this.setState({ maturityCap }, this.updateOwnership);
  }

  updateProRataRights(value) {
    const proRataRightsCapital = this.cleanInput(value)
    if (!proRataRightsCapital) return

    this.setState({ proRataRightsCapital }, this.updateOwnership);
  }

  safeFields() {
    if (this.state.roundType != 'safe') return

    return (
      <React.Fragment>
        <Row>
          <Col sm='12' md='12' lg='12'>
            <div className='note-calculator-label'
                 data-tip='Allows investors to buy discounted shares if it generates greater ownership than implied equity'>
              Discount
            </div>

            <PercentageInput
              className='form-control'
              onChange={(value) => this.updateDiscount(value)}
              value={this.state.discount}
            />
          </Col>
        </Row>
      </React.Fragment>
    )
  }

  pricedRoundFields() {
    if (this.state.roundType != 'priced') return

    return (
      <React.Fragment>
        <Row>
          <Col>
            <div className='note-calculator-label'
                 data-tip="Amount of equity reserved for employees stock options.">
              Employees Option Pool
            </div>

            <PercentageInput
              className='form-control'
              onChange={this.updateOptionPool}
              value={this.state.optionPool}
            />
          </Col>
        </Row>
        <Row>
          <Col sm='2' md='2' lg='2'>
            <Toggle
              icons={{
                checked: null,
                unchecked: null,
              }}
              defaultChecked={this.state.optionsAreOnPostMoneyCap}
              onChange={this.updateOptionPoolDilution} />
          </Col>

          <Col sm='10' md='10' lg='10'>
           <label data-tip='If on a pre-money basis, the option pool will be diluted by the investors in this round.'>
             Option Pool is on a post-money basis?
           </label>
          </Col>
        </Row>

        <Row>
          <Alert color='warning' isOpen={this.state.optionPool > 0} style={{marginTop: '18px'}}>
            {this.state.optionsAreOnPostMoneyCap ?
              <span>Since the option pool is on a post-money basis, it will be diluted by {this.state.impliedOwnership.toFixed(2)}% by investors in this round.
              The pre-money option pool size will be grossed up to {this.state.impliedOptionPool.toFixed(2)}% to account for that dilution.
              This means previous investors and founders will be diluted an extra {(this.state.impliedOptionPool - this.state.optionPool).toFixed(2)}%</span> :
              <span>Since the option pool is on a pre-money basis, it will be diluted by investors in this round by {this.state.investorsOwnership.toFixed(2)}%.
              This will dilute it from {this.state.optionPool.toFixed(2)}% to {this.state.dilutedOptionPool.toFixed(2)}%.</span>
            }
          </Alert>
        </Row>
      </React.Fragment>
    )
  }

  updateOptionPool(value) {
    const optionPool = this.cleanInput(value)
    if (optionPool != 0 && !optionPool) return

    this.setState({ optionPool }, this.updateOwnership);
  }


  updateOptionPoolDilution() {
    this.setState({optionsAreOnPostMoneyCap: !this.state.optionsAreOnPostMoneyCap}, this.updateOwnership)
  }

  convertibleNoteFields() {
    if (this.state.roundType != 'note') return

    return (
      <React.Fragment>
        <Row>
          <Col sm='12' md='12' lg='6'>
            <div className='note-calculator-label'
                 data-tip='Allows investors to buy discounted shares if it generates greater ownership than implied equity'>
              Discount
            </div>

            <PercentageInput
              className='form-control'
              onChange={(value) => this.updateDiscount(value)}
              value={this.state.discount}
            />
          </Col>

          <Col sm='12' md='12' lg='6'>
            <div className='note-calculator-label'
                 data-tip="The valuation of the company at the maturity date if a priced round doesn't happen">
              Conversion Price @ Maturity
            </div>

            <DollarsInput
              className='form-control'
              onChange={(value) => this.updateMaturityCap(value)}
              value={this.state.maturityCap}
            />
          </Col>
        </Row>

        <Row>
          <Col sm='12' md='12' lg='6'>
            <div className='note-calculator-label'
                 data-tip="The date when the note automatically converts to equity if a priced round (i.e. qualifed financing) doesn't happen">
              Months To Maturity ({new Date(this.state.maturityDate).toLocaleDateString()})
            </div>

            <input className='form-control'
                   type='number'
                   onChange={(value) => { this.updateMonthsToMaturity(value) }}
                   value={this.state.monthsToMaturity} />
          </Col>

          <Col sm='12' md='12' lg='6'>
            <div className='note-calculator-label'
                 data-tip="The amount of interest accrued to the investors prior to the note's conversion to equity.">
              Interest Rate ({this.formatMoney(this.accruedInterest())} accrued)
            </div>

            <PercentageInput
              className='form-control'
              onChange={(value) => this.updateInterestRate(value)}
              value={this.state.interestRate}
            />
          </Col>
        </Row>
      </React.Fragment>
    )
  }

  formInputs() {
      return (
        <React.Fragment>
          {!this.state.uncapped &&
            <Row>
              <Col sm='12' md='12' lg='6'>
                <div className='note-calculator-label'
                     data-tip="The valuation of the company before new money (round size) is added to the valuation">
                  Pre Money Valuation
                </div>

                <DollarsInput
                  className='form-control'
                  onChange={(value) => this.updatePreMoneyValuation(value)}
                  value={this.state.preMoney}
                  disabled={!this.state.usePreMoneyCap}
                />
              </Col>
              <Col sm='12' md='12' lg='6'>
                <div className='note-calculator-label'
                     data-tip="The valuation of the company after adding new money (round size) to the valuation">
                  Post Money Valuation
                </div>

                <DollarsInput
                  className='form-control'
                  onChange={(value) => this.updatePostMoneyValuation(value)}
                  value={this.state.postMoney}
                  disabled={this.state.usePreMoneyCap}
                />
              </Col>
            </Row>
          }

          <Row>
            <Col sm='12' md='12' lg='6'>
              <div className='note-calculator-label'
                   data-tip="The day in which the round is closed. If it's a convertible note, it will start accruing interest after this date.">
                Date of closing
              </div>

              <DayPickerInput
                formatDate={formatDate}
                parseDate={parseDate}
                value={this.state.roundDate}
                onDayChange={this.updateRoundDate}
                selectedDays={this.state.roundDate}
                dayPickerProps={{
                  month: this.state.roundDate,
                }}
                inputProps={{className: 'form-control'}}
              />
            </Col>

            <Col sm='12' md='12' lg='6'>
              <div className='note-calculator-label'
                   data-tip="Amount of capital invested by investors with pro rata rights (Usually called Major Investors in the documents)">
                Capital with Pro Rata Rights
              </div>

              <DollarsInput
                className='form-control'
                onChange={(value) => this.updateProRataRights(value)}
                value={this.state.proRataRightsCapital}
              />
            </Col>
          </Row>

          {this.convertibleNoteFields()}
          {this.safeFields()}
          {this.pricedRoundFields()}
        </React.Fragment>
      )

  }

  capOptions() {
    let options = [
      { value: 'pre', label: 'Pre Money Cap' },
      { value: 'post', label: 'Post Money Cap' }
    ]

    if (this.state.roundType != 'priced') {
      options.push({ value: 'uncapped', label: 'Uncapped' })
    }

    return options
  }

  isPastMaturity() {
    if (this.state.roundType != 'note') return false

    if (this.state.maturityDate && this.props.seriesADate) {
      const selected = new Date(this.state.maturityDate)
      const seriesA = new Date(this.props.seriesADate)
      return selected < seriesA
    } else {
      return false
    }
  }

  removeNote() {
    if (confirm(`Are you sure you want to delete the ${this.state.name} round?`))
    this.props.removeNote(this.props.round.id)
  }

  render() {
    return (
      <div className='note-input'>
        <Row style={{alignItems: 'center'}}>
          <Col xs='7' md='7' sm='7'>
            <h3 className='note-input-round-name'>
              <input
              type="text"
              value={this.state.name}
              onChange={e => this.handleNameChange(e)}
              />
            </h3>
          </Col>

          <Col xs='5' md='5' sm='5'>
            <Button outline onClick={() => { this.setState({collapsed: !this.state.collapsed})}}
                    color="primary" size="sm">
              {this.state.collapsed ? 'Show' : 'Hide'} Fields
            </Button>

            <Button outline onClick={this.removeNote}
                    style={{marginLeft: '8px'}}
                    color="danger" size="sm">
              X
            </Button>
          </Col>
        </Row>

        {!this.state.collapsed &&
          <React.Fragment>
            <Row style={{marginBottom: '24px'}}>
              <Col sm='12' md='12' lg='12'>
                <div className='note-calculator-label'>
                  Round Type
                </div>

                <Select
                  value={this.state.roundTypeOption}
                  onChange={this.updateRoundType}
                  placeholder={'Select round type'}
                  options={[{ value: 'note', label: 'Convertible Note' },
                            { value: 'safe', label: 'SAFE' },
                            { value: 'priced', label: 'Priced Round' }]}
                />
              </Col>
            </Row>

            <Row>
              <Col sm='12' md='12' lg='6'>
                <div className='note-calculator-label'
                     data-tip="Uncapped means it will always convert at a discount on the first qualifying financing round's cap.">
                  Cap Type
                </div>

                <Select
                  value={this.state.selectedCapOption}
                  onChange={this.updatePreOrPostCap}
                  placeholder={'Select cap'}
                  options={this.capOptions()}
                />
              </Col>

              <Col sm='12' md='12' lg='6'>
                <div className='note-calculator-label'
                     data-tip='The total amount of money raised in the current round'>
                  Amount Raised
                </div>

                <DollarsInput
                  className='form-control'
                  onChange={(value) => this.updateRoundSize(value)}
                  value={this.state.roundSize}
                />
              </Col>
            </Row>

            {this.formInputs()}
          </React.Fragment>
        }

        <Alert color='warning' isOpen={!this.state.capConversion || this.state.uncapped}>
          The {this.state.name} investors will convert at a ${((this.props.seriesAPostMoney / 1000000) * (1 - (this.state.discount / 100))).toFixed(2)}M post money valuation,
          a {this.state.discount}% discount on the ${this.props.seriesAPostMoney / 1000000}M Series A cap.
        </Alert>

        <Alert color='warning' isOpen={this.isPastMaturity()}>
          The Series A round is after the maturity date of the {this.state.name} note.
          It will convert at a {this.formatMoney(this.state.maturityCap)} cap.
        </Alert>

        <Alert color='danger' isOpen={this.state.roundSize > this.state.postMoney}>
          Your {this.state.name} round size is larger than the valuation of the round. You can't sell more than 100% of your equity!
        </Alert>

        <ReactTooltip />
      </div>
    )
  }
}
