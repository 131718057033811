import React from 'react';
import NumberFormat from 'react-number-format';

export default class DollarsInput extends React.Component {
  render() {
    return (
      <NumberFormat
        {...this.props}
        className={`form-control ${this.props.className}`}
        thousandSeparator={true}
        prefix={'$'} />
    )
  }
}
