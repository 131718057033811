import React from 'react';
import MaskedInput from 'react-text-mask'
import createNumberMask from 'text-mask-addons/dist/createNumberMask'

export default class PercentageInput extends React.Component {
  render() {
    const mask = createNumberMask({
      prefix: '',
      suffix: '%',
      thousandsSeparatorSymbol: ',',
      allowDecimal: true,
      decimalSymbol: '.',
      requireDecimal: false,
      decimalLimit: null
    });

    return (
      <MaskedInput
        {...this.props}
        mask={mask}
        className='form-control'
      />
    )
  }
}
